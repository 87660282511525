<template>
  <div class="addressList-box">
    <div class="group-box">
      <div class="address-big-group-box">
        <span @click="titleClick">
          竹香学校智慧校园</span>
        <el-button size="mini"
                   v-if="role"
                   icon="el-icon-plus"
                   style="margin-left: 10px"
                   @click="addGroup">新增
        </el-button>
      </div>
      <!-- 拖拽vuedraggable -->
      <div class="group-title">科组</div>
      <vuedraggable v-model="sectionList"
                    @change="onaddSectionList">
        <transition-group>
          <div v-for="address in sectionList"
               :key="address.id"
               @click="groupClick(address)"
               class="address-group-box"
               :style="address.id === currentGroupID?'background-color:#e9f5ee':''">
            <span>{{address.name}}</span>
            <span style="float: right;"
                  v-if="role">
              <el-link type="primary"
                       class="link-box"
                       @click="modifyGroup(address)"
                       icon="el-icon-edit"></el-link>
              <el-link icon="el-icon-delete"
                       class="link-box"
                       @click="deleteGroup(address)"
                       type="danger"></el-link>
            </span>
          </div>
        </transition-group>
      </vuedraggable>
      <div class="group-title">常规组</div>
      <vuedraggable v-model="generalGroup"
                    @change="onaddGeneralGroup">
        <transition-group>
          <div v-for="address in generalGroup"
               :key="address.id"
               @click="groupClick(address)"
               class="address-group-box"
               :style="address.id === currentGroupID?'background-color:#e9f5ee':''">
            <span>{{address.name}}</span>
            <span style="float: right;"
                  v-if="role">
              <el-link type="primary"
                       class="link-box"
                       @click="modifyGroup(address)"
                       icon="el-icon-edit"></el-link>
              <el-link icon="el-icon-delete"
                       class="link-box"
                       @click="deleteGroup(address)"
                       type="danger"></el-link>
            </span>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <div class="right-box">
      <el-button type="success"
                 @click="addMemberCilck"
                 v-show="memberTable.id"
                 v-if="role"
                 class="button-box">添加成员
      </el-button>
      <div class="flex-box">
        <el-input v-model="queryName"
                  placeholder="请输入姓名"></el-input>
        <el-button type="primary"
                   @click="loadAddressMember">搜索
        </el-button>
      </div>
      <!-- 成员表格 -->
      <el-table :data="memberTable.member"
                class="memberTable-box">
        <el-table-column prop="mname"
                         align="center"
                         label="姓名"></el-table-column>
        <el-table-column prop="job"
                         align="center"
                         label="职务"></el-table-column>
        <el-table-column prop="course"
                         align="center"
                         label="学科"></el-table-column>
        <el-table-column prop="typeStr"
                         align="center"
                         label="类别"></el-table-column>
        <el-table-column prop="mobile"
                         align="center"
                         label="手机号码"></el-table-column>
        <el-table-column prop="email"
                         align="center"
                         label="邮箱"></el-table-column>
        <el-table-column prop="tel"
                         align="center"
                         label="办公电话"></el-table-column>
        <el-table-column label="操作"
                         align="center"
                         width="180px">
          <template slot-scope="scope">
            <el-button type="primary"
                       @click="modifyMember(scope.row)">修 改
            </el-button>
            <el-button type="danger"
                       @click="deleteMember(scope.$index,scope.row)">删 除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 添加成员 -->
      <el-dialog :visible.sync="addMemberVisible"
                 width="600px"
                 title="添加成员">
        <el-form :model="addMemberForm"
                 ref="addMemberForm"
                 :rules="addMemberFormRule"
                 label-width="80px">
          <el-form-item label="组名"
                        v-if="memberTable.id"
                        prop="groupName">
            <el-input v-model="addMemberForm.groupName"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="职务"
                        prop="job">
            <el-input v-model="addMemberForm.job"></el-input>
          </el-form-item>
          <el-form-item label="姓名"
                        prop="name">
            <el-select v-model="addMemberForm.name"
                       filterable
                       value-key="id">
              <el-option v-for="teacher in teacherList"
                         :key="teacher.id"
                         :label="teacher.name"
                         :value="{ name: teacher.name, id: teacher.id }"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="办公电话"
                        prop="officeTelephone">
            <el-input v-model="addMemberForm.officeTelephone"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="addMemberVisible=false">取 消</el-button>
          <el-button type="primary"
                     @click="addMemberConfirm">确 定
          </el-button>
        </div>
      </el-dialog>
      <!-- 添加分组 -->
      <el-dialog :visible.sync="addGroupVisible"
                 width="600px"
                 title="添加分组">
        <el-form :model="addGroupForm"
                 ref="addGroupForm"
                 label-width="80px">
          <el-form-item label="名称"
                        prop="groupName">
            <el-input v-model="addGroupForm.groupName"></el-input>
          </el-form-item>
          <el-form-item label="类别"
                        required
                        prop="type">
            <el-select v-model="addGroupForm.type"
                       value-key="id"
                       placeholder="请选择类别">
              <el-option v-for="type in typeList"
                         :key="type.id"
                         :label="type.name"
                         :value="{ name: type.name, id: type.id }"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科"
                        v-if="addGroupForm.type"
                        required
                        prop="subjectId">
            <el-select v-model="addGroupForm.subjectId"
                       value-key="id"
                       placeholder="请选择学科">
              <el-option v-for="subject in subjectList"
                         :key="subject.id"
                         :label="subject.course"
                         :value="subject.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="addGroupVisible=false">取 消</el-button>
          <el-button type="primary"
                     @click="addGroupConfirm">确 定
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";

export default {
  name: "addressList",
  components: { vuedraggable },
  props: {},
  data() {
    return {
      addMemberVisible: false,
      addGroupVisible: false,
      sectionList: [],
      generalGroup: [],
      addMemberForm: {
        job: "",
        name: null,
        officeTelephone: "",
        groupName: "",
        id: "",
      },
      addMemberFormRule: {
        job: [{ required: true, message: "请输入职务", trigger: "blur" }],
        name: [{ required: true, message: "请选择教师名字", trigger: "blur" }],
        officeTelephone: [
          { required: true, message: "请输入办公电话", trigger: "blur" },
        ],
      },
      addGroupForm: {
        groupName: "",
        type: null,
        subjectId: "",
        id: "",
      },
      subjectList: [],
      memberTable: { member: [], id: "" },
      teacherList: [],
      typeList: [
        { name: "常规组", id: 0 },
        { name: "科组", id: 1 },
      ],
      queryName: "",
      currentGroupID: "",
      currentGroupSort: "",
      currentGroupType: 1,
      currentGroupName: "",
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      role: false,
    };
  },
  created() {
    this.loadAddressList();
    this.loadAddressMember();
    this.loadCourse();
    //判断该用户是否用该模块的管理权限，id=108为课表管理

    this.userInfo.nav1.forEach((moduleRe) => {
      if (moduleRe.id === 201) {
        this.role = true;
      }
    });
  },
  methods: {
    onaddGeneralGroup(event) {
      let oldIndex = event.moved.oldIndex; //拖拽元素原先的index
      let newIndex = event.moved.newIndex; //拖拽到新位置后的index
      //往下拖拽
      let oldId = event.moved.element.id;
      let newId = this.generalGroup[newIndex - 1].id;
      if (oldIndex > newIndex) {
        newId = this.generalGroup[newIndex + 1].id;
      }
      const that = this;
      this.$post("/media/updateBookSort.do", { id: oldId, newid: newId })
        .then((res) => {})
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    onaddSectionList(event) {
      let oldIndex = event.moved.oldIndex; //拖拽元素原先的index
      let newIndex = event.moved.newIndex; //拖拽到新位置后的index
      //往下拖拽
      let oldId = event.moved.element.id;
      let newId = this.sectionList[newIndex - 1].id;
      if (oldIndex > newIndex) {
        newId = this.sectionList[newIndex + 1].id;
      }
      const that = this;
      this.$post("/media/updateBookSort.do", { id: oldId, newid: newId })
        .then((res) => {})
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadAddressList() {
      const that = this;
      this.$post("/media/queryBook.do", {})
        .then((res) => {
          that.generalGroup = [];
          that.sectionList = [];
          res.data.forEach((group) => {
            if (group.type === 0) {
              this.generalGroup.push(group);
            } else {
              this.sectionList.push(group);
            }
          });
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadAllTeacher() {
      const that = this;
      //查询身份是否校内，1是校内
      this.$post("/media/queryAllManager.do", { is_no: 1 })
        .then((res) => {
          that.teacherList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadCourse() {
      const that = this;
      this.$post("/media/queryCourse.do", {})
        .then((res) => {
          that.subjectList = res.data;
        })
        .catch((error) => {
          that.$message.error(error.message);
        });
    },
    loadAddressMember() {
      const that = this;
      let data = { bookid: "", name: "", courseid: "" };
      if (this.currentGroupID) {
        data.bookid = this.currentGroupID;
      }
      if (this.queryName) {
        data.name = this.queryName;
      }
      this.$post("/media/queryBookManager.do", data)
        .then((res) => {
          that.memberTable.member = res.data;
          if (that.currentGroupID) {
            that.memberTable.id = that.currentGroupID;
          }
        })
        .catch((error) => {
          that.$message.error(error.message);
        });
    },
    titleClick() {
      this.currentGroupID = "";
      this.memberTable.id = "";
      this.loadAddressMember();
    },
    addGroup() {
      this.addGroupVisible = true;
      this.$nextTick(() => {
        this.$refs["addGroupForm"].resetFields();
        this.addGroupForm.id = "";
      });
    },
    addMemberCilck() {
      this.addMemberVisible = true;
      this.loadAllTeacher();
      this.$nextTick(() => {
        this.$refs["addMemberForm"].resetFields();
        this.addMemberForm.groupName = this.currentGroupName;
        this.addMemberForm.id = "";
      });
    },
    modifyMember(member) {
      this.addMemberVisible = true;
      this.addMemberForm.groupName = member.name;
      this.loadAllTeacher();
      this.loadCourse();
      this.$nextTick(() => {
        this.addMemberForm.name = member.mname;
        this.addMemberForm.job = member.job;
        this.addMemberForm.officeTelephone = member.tel;
        this.addMemberForm.id = member.id;
      });
    },
    addMemberConfirm() {
      this.$refs["addMemberForm"].validate((valid) => {
        if (valid) {
          const that = this;
          let data = {
            id: this.memberTable.id,
            managerid: this.addMemberForm.name.id,
            tel: this.addMemberForm.officeTelephone,
            job: this.addMemberForm.job,
            courseid: 1,
          };
          let apiUrl = "/media/insertBookManager.do";
          if (this.addMemberForm.id) {
            data.id = this.addMemberForm.id;
            apiUrl = "/media/updateBookManager.do";
          }
          this.$confirm("是否确认该操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  that.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  that.addMemberVisible = false;
                  that.loadAddressMember();
                })
                .catch((err) => {
                  that.$message.error(err.message);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        } else {
          return false;
        }
      });
    },
    deleteMember(index, member) {
      const that = this;
      this.$confirm("是否确认该操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteBookManager.do", { id: member.id })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadAddressMember();
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addGroupConfirm() {
      const that = this;
      this.$refs["addGroupForm"].validate((valid) => {
        if (valid) {
          let data = {
            name: this.addGroupForm.groupName,
            type: this.addGroupForm.type.id,
            courseid: this.addGroupForm.subjectId,
          };
          let apiUrl = "/media/insertBook.do";
          if (this.addGroupForm.id) {
            data.id = this.addGroupForm.id;
            apiUrl = "/media/updateBook.do";
          }
          this.$confirm("是否确认该操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  that.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  that.addGroupVisible = false;
                  that.currentGroupID = res.data;
                  that.currentGroupName = this.addGroupForm.groupName;
                  that.loadAddressList();
                  that.loadAddressMember();
                })
                .catch((err) => {
                  that.$message.error(err.message);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        }
      });
    },
    modifyGroup(address) {
      this.addGroupVisible = true;
      this.$nextTick(() => {
        this.addGroupForm.groupName = address.name;
        this.addGroupForm.type = address.typeStr;
        this.addGroupForm.subjectId = address.courseid;
        this.addGroupForm.id = address.id;
      });
    },
    deleteGroup(address) {
      const that = this;
      this.$confirm("是否确认该操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteBook.do", { id: address.id })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.currentGroupID = "";
              that.loadAddressList();
              that.loadAddressMember();
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    groupClick(group) {
      this.currentGroupID = group.id;
      this.currentGroupName = group.name;
      this.currentGroupSort = group.sort_index;
      this.currentGroupType = group.type;
      this.loadAddressMember();
    },
  },
};
</script>

<style scoped lang="scss">
.addressList-box {
  display: flex;
}

.button-box {
  position: absolute;
}

.group-title {
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
}

.group-box {
  display: flex;
  flex-direction: column;
  width: 20%;
  flex: 1;
}

.right-box {
  width: 80%;
}

.address-big-group-box {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
  width: 230px;
}

.address-big-group-box:hover .link-box {
  display: inline;
}

.link-box {
  margin-left: 10px;
  display: none;
}

.address-group-box {
  font-size: 16px;
  cursor: pointer;
  padding: 8px 10px;
  width: 80%;
  border-radius: 5px;
}

.address-group-box:hover {
  background-color: #f5fff5;
}

.address-group-box:hover .link-box {
  display: inline;
}

.flex-box {
  display: flex;
  margin-left: auto;
  width: 300px;
}

.flex-box > button {
  margin-left: 5px;
}

.memberTable-box {
  margin-top: 20px;
}
</style>
